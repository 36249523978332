/* eslint-disable react-hooks/exhaustive-deps */
import { rgba } from 'polished';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Col, Grid, Row } from 'react-styled-flexboxgrid';
import styled from 'styled-components';
import { RootState } from '../../store';
import {
  loadOptionLists,
  sendToCustomer,
} from '../../store/configurator/actions';
import {
  Configuration,
  DEPENDENCY_TYPE_GROUP
} from '../../store/configurator/types';
import { loadProducts } from '../../store/product/actions';
import { ProductState } from '../../store/product/types';
import {
  getOptionLists,
  getVisibleOptionLists,
  getSelectedOptionListsAsQueryParams,
} from '../../utils/optionList';
import Button, { ButtonGroup } from '../atoms/Button';
import Link from '../atoms/Link';
import Logo from '../atoms/Logo';
import UserPreviewForm, { FormTypes, UserValues } from '../molecules/Form/UserPreviewForm';
import OptionLine, {
  FrameOptionLine,
  SubtotalOptionLine,
  TotalOptionLine,
  DiscountOptionLine,
  ExchangeBikeOptionLine,
} from '../molecules/OptionLine';
import OptionLines from '../molecules/OptionLines';
import Overview from '../molecules/Overview';
import Bike from '../organisms/Bike';
import Discount from '../organisms/Discount';
import {
  ExchangeBike,
  OrderOptions,
} from '../../store/order/types';
// @ts-ignore
import { placeOrder } from '../../store/order/actions';
import { FormikHelpers } from 'formik';
import api from '../../api';
import { generatePDF } from '../organisms/OverviewPDF';
import { getOrderData } from '../../utils/order';
import { loadGlobals } from '../../store/globals/actions';

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 2rem 0;
`;

const Title = styled.h3`
  font-weight: 900;
  color: ${({ theme }) => theme.colors.gray[100]};
  text-transform: uppercase;
  font-size: 6rem;
  line-height: 6rem;
  text-align: center;
  margin: 1rem 0 -15% 0;
`;

const BikeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background-color: ${({ theme }) => rgba(theme.colors.gray[50], 0.5)};
  padding-top: 4rem;

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 12%;
    background-color: #fff;
    bottom: 0;
  }
`;

const OptionLinesWrapper = styled.div`
  padding: 0 3.5rem;
  display: flex;
`;

interface IAuthorizationData {
  success: boolean;
  claims: { type: string, value: string }[];
}

const Order: FC = () => {
  // State.
  const [authorizing, setAuthorizing] = useState(false);
  const [authorized, setAuthorized] = useState(false);
  const [hasModuleOrders, setHasModuleOrders] = useState(false);
  const [showDiscount, setShowDiscout] = useState(false);

  // Router:
  const id = parseInt((useParams() as { id: string }).id);
  const history = useHistory();

  // Redux:
  const dispatch = useDispatch();
  const configurations = useSelector<RootState, Configuration[]>(
    ({ configurator }) => configurator.configurations,
  );

  const product = useSelector<RootState, ProductState>(
    ({ product }) => product,
  );

  const exchangeBikeActive = useSelector<RootState, boolean>(
    ({ order }) => order.exchangeBikeActive,
  );

  const exchangeBike = useSelector<RootState, ExchangeBike>(
    ({ order }) => order.exchangeBike,
  );

  const totalPriceDiscount = useSelector<RootState, number>(
    ({ order }) => order.totalPriceDiscount ?? 0,
  );

  // Order's lifecycle.
  useEffect(() => {
    isAuthorized();
  }, [id]);

  useEffect(() => {
    dispatch(loadProducts());
  }, []);

  useEffect(() => {
    dispatch(loadGlobals());
  }, []);

  useEffect(() => {
    if (!product.products.length || id == null) {
      return;
    }

    dispatch(loadOptionLists(id));

    if (!product.products.find((product) => product.id === id)) {
      history.push('/');
    }
  }, [product, id]);

  // Order's methods:
  const getExchangeBikeRemarks = (comments?: string, sellerCode?: string) => {
    let remarks = exchangeBikeActive
      ? Object.entries(exchangeBike)
        .filter(
          ([key]) =>
            ['image', 'uploadedFile'].findIndex(
              (k) => k === key,
            ) === -1,
        )
        .reduce((r, [key, value]) => (r += `${key}: \t${value}\n`), '')
      : '';

    if (sellerCode != null) {
      remarks = remarks.concat(`=====================================\nsellerCode: \t${sellerCode}\n`);
    }

    if (!!exchangeBike.uploadedFile) {
      remarks = remarks.concat(
        `=====================================\nAfbeelding: \thttps://cdn.bluenotion.nl/${exchangeBike.uploadedFile.fileName}\n`
      );
    }

    // if (!!discountCode) {
    //   remarks = remarks.concat(
    //     `=====================================\Kortingscode: \t${discountCode}`
    //   );
    // }

    if (!!comments) {
      remarks = remarks.concat(
        `===================================== Opmerkingen: \t${comments}`
      )
    }

    return remarks;
  };

  const isAuthorized = async () => {
    if (authorizing) {
      return;
    }

    setAuthorizing(true);

    api
      .get<IAuthorizationData>(`${process.env.REACT_APP_API_ROOT}account/authorized`)
      .then((data) => {
        setAuthorized(true)
        const hasModuleOrders = data.data.claims.some(c => c.type === 'api.bluenotion.companyModules' && c.value === `Orders|${process.env.REACT_APP_COMPANY_ID}`);
        setHasModuleOrders(hasModuleOrders);
      })
      .catch(() => setAuthorized(false))
      .finally(() => setAuthorizing(false));
  };

  const onSubmit = async (
    values: UserValues,
    helpers: FormikHelpers<UserValues>,
    formType: FormTypes
  ) => {
    if (id == null) {
      throw new Error('Order id is niet bekend');
    }
    const remarks = getExchangeBikeRemarks(values.comments, values.sellerCode);

    const data = getOrderData(id, values, configurations, remarks, totalPriceDiscount);

    if (values.shareDesign) {
      const params = getSelectedOptionListsAsQueryParams(optionLists);
      const active = product.products.find((product) => product.id === id);

      if (active) {
        const path = `${window.location.origin}/${active.id}/configureren?${params}`;
        const pdf = await generatePDF(getVisibleOptionLists(optionLists), active, path, totalPriceDiscount);

        dispatch(
          sendToCustomer({
            configuration: pdf,
            configurationUrl: path,
            customerEmail: values.email,
            customerPhoneNumber: values.number,
            shareWithCC5311: values.shareDesignStore,
          }),
        );
      }
    }

    const orderOptions: OrderOptions | undefined =
    {
      initialStateId: formType === 'QUOTATION' ? 1790 : undefined,
      storeCurrentUserAsOrderBackofficeUser: true,
    };

    return dispatch(placeOrder(data, orderOptions));
  };

  // Render:
  const { optionLists } = getOptionLists(configurations, id);
  const filteredOptionLists = getVisibleOptionLists(optionLists).filter((optionList) => !optionList.dependencies.some((dependency) => dependency.type === DEPENDENCY_TYPE_GROUP));

  return (
    <Grid>
      <Header>
        <Link to={`/${id}/configureren`}>
          <Button>Terug</Button>
        </Link>
        <ButtonGroup>
          <Button onClick={() => setShowDiscout(!showDiscount)}>Prijs</Button>
          <Link to={`/${id}/bestelling/inruilformulier`}>
            <Button primary={exchangeBikeActive}>Inruilformulier</Button>
          </Link>
          <Discount
            toggle={() => setShowDiscout(!showDiscount)}
            show={showDiscount}
          />
        </ButtonGroup>
      </Header>
      <Row>
        <Col xs={12} md={4}>
          <UserPreviewForm onSubmit={onSubmit} hasModuleOrders={hasModuleOrders} />
        </Col>
        <Col xs={12} md={8}>
          <Overview>
            <BikeWrapper>
              <Logo />
              <Title>Jouw ontwerp</Title>
              <Bike />
            </BikeWrapper>
            <OptionLinesWrapper>
              <OptionLines>
                <tbody>
                  <FrameOptionLine />
                  {filteredOptionLists.map((optionList) => (
                    <OptionLine
                      authorized={authorized}
                      key={optionList.optionListId}
                      optionList={optionList}
                    />
                  ))}
                  <SubtotalOptionLine />
                  {!!exchangeBikeActive && (
                    <ExchangeBikeOptionLine
                      brand={exchangeBike.brand}
                      type={exchangeBike.type}
                      modelYear={exchangeBike.modelYear}
                      price={exchangeBike.price}
                    />
                  )}
                  {totalPriceDiscount > 0 && <DiscountOptionLine discount={totalPriceDiscount} />}
                  <TotalOptionLine discount={totalPriceDiscount} exchangeBikeReturnAmount={exchangeBike.price} />
                </tbody>
              </OptionLines>
            </OptionLinesWrapper>
          </Overview>
        </Col>
      </Row>
    </Grid>
  );
};

export default Order;
